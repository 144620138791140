define('ember-cli-dropcap/components/drop-cap', ['exports', 'dropcap'], function (exports, _dropcap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',

    didRender: function didRender() {
      this._super.apply(this, arguments);
      _dropcap.default.layout(this.$()[0], this.get('heightInLines'), this.get('baselinePos'));
    }
  });
});